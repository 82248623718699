// @file Network requests related to google app licensing settings

import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type { GoogleAppLicensingValidateResponse } from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

const API_VERSION = 5

class GoogleAppLicensingSettingsApi extends FetchableObject {
  public static async checkLicense(): Promise<JsonAPIResponse<GoogleAppLicensingValidateResponse>> {
    return await fetchJson(`api/${API_VERSION}/google-app-licensing/validate-or-logout`, {
      method: HTTPMethod.get,
    })
  }
}

export { GoogleAppLicensingSettingsApi }
